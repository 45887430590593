import * as React from "react"
import Layout from "../../components/layout"
import messages from "../../i18n/it"
import GeocachingCulturalRoute from "../../components/pageComponents/geocachingCulturalRoute"

const GeocachingCulturalRoutePage = ({ location }) => (
    <Layout location={location} messages={messages}>
        <GeocachingCulturalRoute langKey="it" />
    </Layout>
)

export default GeocachingCulturalRoutePage
